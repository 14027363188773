import {BaseItem} from './common.model';

export enum UserRole {
  ClientUser = 'CLIENT_USER',
  ClientAdmin = 'CLIENT_ADMIN',
  FinlisticsAdmin = 'FINLISTICS_ADMIN',
}

export enum UserRoleUids {
  ClientUser = '75694c31-3df8-4ec5-b967-b15b75db993d',
  ClientAdmin = '2e424e0f-6680-4c1d-9923-f9def5e8c60a',
  FinlisticsAdmin = '4bb7a56e-d70b-4fdd-9fea-7032f942fe38',
}

export interface User extends BaseItem {
  clientUid: string;
  clientName: string;
  clientCode: string;
  legacyId?: string;
  legacySiteId?: string;
  email: string;
  avatarUrl: string;
  roleNames: UserRole[];
  clientCustomLogo?: boolean;
}

export interface UserSettings {
  code: UserSettingCode;
  value: string;
}

export enum UserSettingCode {
  ImprovementDecimalPlaces = 'IMPROVEMENT_DECIMAL_PLACES',
  IncludePrivateCompanySearch = 'INCLUDE_PRIVATE_COMPANY_SEARCH_RESULTS',
  SalesForceIntegration = 'SALESFORCE_INTEGRATION_ENABLED',
  DisplayCustomLogo = 'DISPLAY_CUSTOM_LOGO',
  AccountPlanTerm = 'ACCOUNT_PLAN_TERM',
  AccountPlanCreateDescription = 'ACCOUNT_PLAN_CREATE_DESCRIPTION',
}
